import { FC } from 'react';

import { hasRichTextContent } from '@lib/slice-machine/richText';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, Grid } from '@mui/material';
import { CustomRichText } from '@ui/slice-machine/CustomRichText';
import {
  BannerCardWithImages as BannerCardWithImagesProps,
  BannerCardWithImagesVariation,
} from '../types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DynamicImage } from '@ui/gallery/DynamicImage';
import { BannerCardContent } from './BannerCardContent';
import { SliceLayout } from '@ui/slice-machine/SliceLayout';
import { BannerCardPanel } from './BannerCardPanel';
import { SxTheme } from '@lib/theme/types';
import { DEFAULT_SPACING_BETWEEN_ELEMENTS } from '../../constants';

interface Props {
  slice: BannerCardWithImagesVariation;
}

export const BannerCardWithImages: FC<Props> = ({ slice }) => {
  const {
    title,
    backgroundColor,
    imageInFrontOfCard,
    imageBehindCard,
    imageBehindCardPosition,
    paddingTop,
    paddingBottom,
    width,
    showOnlyPanelContent,
    showTitleInsidePanel,
  } = slice.primary;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const hasTopRightImage =
    !!imageBehindCard?.url && imageBehindCardPosition === 'top-right';
  const includeExtraTopPadding =
    !hasRichTextContent(title) &&
    (!!imageInFrontOfCard?.url || hasTopRightImage);
  const extraTopPadding = calculateExtraTopPadding(
    includeExtraTopPadding,
    isMobile,
    hasTopRightImage,
  );
  const styles = getStyles({
    isMobile,
    imageBehindCardPosition,
    extraTopPadding,
  });

  return (
    <SliceLayout
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      width={width}
      contentProps={{ sx: styles.getValue('layout') }}
    >
      <Grid container>
        {!showTitleInsidePanel && hasRichTextContent(title) && (
          <Grid
            item
            xs={12}
            justifyContent="center"
            sx={styles.getValue('title')}
          >
            <CustomRichText render={title} />
          </Grid>
        )}
        <Grid item xs={12} container sx={styles.getValue('cardWrapper')}>
          <Box sx={styles.getValue('relativeContainer')}>
            {imageBehindCard?.url && (
              <DynamicImage
                containerProps={{ sx: styles.getValue('imageBehindCard') }}
                src={imageBehindCard.url}
                alt={imageBehindCard?.alt ?? ''}
                fill
                objectFit={'contain'}
              />
            )}
            {imageInFrontOfCard?.url && (
              <DynamicImage
                containerProps={{ sx: styles.getValue('imageInFrontOfCard') }}
                src={imageInFrontOfCard.url}
                alt={imageInFrontOfCard?.alt ?? ''}
                fill
                objectFit={'contain'}
              />
            )}
            {showOnlyPanelContent ? (
              <BannerCardPanel slice={slice} />
            ) : (
              <BannerCardContent slice={slice} />
            )}
          </Box>
        </Grid>
      </Grid>
    </SliceLayout>
  );
};

interface StyleProps {
  isMobile: boolean;
  imageBehindCardPosition: BannerCardWithImagesProps['imageBehindCardPosition'];
  extraTopPadding?: number;
}

const getStyles = ({
  isMobile,
  imageBehindCardPosition,
  extraTopPadding,
}: StyleProps) => {
  return new SxStyles({
    layout: {
      overflow: 'hidden',
    },
    cardWrapper: {
      justifyContent: 'center',
      position: 'relative',
      pt: extraTopPadding,
    },
    relativeContainer: {
      position: 'relative',
      width: '100%',
    },
    title: {
      textAlign: 'center',
      mb:
        imageBehindCardPosition === 'bottom-left'
          ? DEFAULT_SPACING_BETWEEN_ELEMENTS
          : 10,
    },
    imageBehindCard: {
      width: isMobile ? 200 : 300,
      height: isMobile ? 200 : 300,
      objectFit: 'contain',
      position: 'absolute',
      zIndex: 0,
      ...convertImagePositionToCss(imageBehindCardPosition, isMobile),
    },
    imageInFrontOfCard: {
      width: isMobile ? 200 : 300,
      height: isMobile ? 100 : 200,
      objectFit: 'contain',
      position: 'absolute',
      top: isMobile ? '-10%' : '-29%',
      right: isMobile ? '50%' : '10%',
      zIndex: 2,
      transform: 'rotate(-10deg)',
    },
  });
};

const calculateExtraTopPadding = (
  includeExtraTopPadding: boolean,
  isMobile: boolean,
  hasTopRightImage: boolean,
): number | undefined => {
  if (!includeExtraTopPadding) {
    return;
  }

  return isMobile ? (hasTopRightImage ? 15 : 7) : 16.25;
};

export const convertImagePositionToCss = (
  position: BannerCardWithImagesProps['imageBehindCardPosition'] | null,
  isMobile: boolean,
): SxTheme => {
  const cssPosition: Record<
    BannerCardWithImagesProps['imageBehindCardPosition'],
    SxTheme
  > = {
    'top-right': {
      top: isMobile ? '-20%' : '-34%',
      right: '-15%',
    },
    'bottom-left': {
      bottom: isMobile ? '-15%' : '-20%',
      right: '91%',
    },
  };

  return cssPosition[position ?? 'top-right'];
};
